export function scrollTo(elementId?: string) {
    if (elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }

    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

export function scrollToFirstErrorMessage() {
    setTimeout(() => {
        const element = document.querySelector('.error-message');
        if (element) {
            const parentElement = element.parentElement;
            if (parentElement) {
                parentElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
            }
        }
    }, 200);
}
